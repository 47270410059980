@tailwind base;
@tailwind components;
@tailwind utilities;

/***************
FONTS
*****************/
@font-face {
  font-family: "Larsseit";
  src: url("/assets/fonts/Larsseit-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Larsseit";
  src: url("/assets/fonts/Larsseit-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Larsseit";
  src: url("/assets/fonts/Larsseit-Medium.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Larsseit";
  src: url("/assets/fonts/Larsseit-MediumItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Larsseit";
  src: url("/assets/fonts/Larsseit-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Larsseit";
  src: url("/assets/fonts/Larsseit-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}
/**************  
VARIABLES
***************/
$default: #000;
$accent: #f75045;
$lightAccent: #fff8b7;
$grey: #c4c4c4;
$lightGrey: #ebebeb;
$darkGrey: #2e2e2e;
$headFont: "Larsseit", sans-serif;
$defaultFont: "Larsseit", sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headFont;
  line-height: 1.6;
  color: $default;
}
body,
p,
li,
a {
  font-family: $defaultFont;
  font-weight: 300;
  color: $default;
}
body {
  // background: url("/background.jpg");
  // background-size: cover;
  // background-position: center top;
  // background-repeat: no-repeat;
  background:#222829;
}
#success-message {
  display: none;
}
#error-message {
  display: none;
}
#result-text {
  &.invalid {
    color: red;
    font-family: $defaultFont;
  }
}
